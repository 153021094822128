import React  from "react";
import "./add-template.css";
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification,ModalDisplay } from "../reusable/reusable";
import { CreateTemplate,GetLocationApi } from "../homeService";
import Select from 'react-select'



const link = [
  { value: '1', label: 'Direct'},
  { value: '2', label: 'Filter'},
  { value: '3', label: 'None'}
]


const social = [
  { value: '1', label: 'Google' },
  { value: '2', label: 'Facebook'},
  { value: '3', label: 'Yelp'},
  { value: '4', label: 'TrustPilot'},
  { value: '6', label: 'BBB'},
  { value: '7', label: 'Capterra'},
  { value: '8', label: 'YellowPages'},
  { value: '9', label: 'Custom'},
  { value: '5', label: 'None'},
]


const account = [
  { value: '1', label: 'BreezeReview'},
  { value: '2', label: 'BreezeAlert'},
  { value: '3', label: 'BackReview'},
  { value: '4', label: 'BackCoupon'},
  { value: '5', label: 'BackAlert'},
]

const emailReg = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);


export default class AddTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {location_id:'',template_link_id:'',account_id:'',social_idd:'', options:[], name:'',content:'', formErrors: { name: "",content:"",file:""},file:null};
    this.modalshowid = '09';
  }
  

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetLocationApi();
    let companydata = await data.data.data;

    

    var output = await companydata.map(s => ({label:s.title,value:s.id}));
    //console.log(output);
    this.setState({ options : output});

  }


  formValid = ({ formErrors }) => {
    // console.log(formErrors);
    let valid = true;

    Object.values(formErrors).forEach((val) => {
      // console.log(val)
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      //console.log(this.state);
    });
    //console.log(this.state)
    let newform = this.state.formErrors;
    switch (name) {
      case "name":
        newform.name =  value.length > 0 ? "" : "Name field cannot be empty";
        break;

        case "content":
        newform.content = value.length > 0 ? "" : "Content field cannot be empty";
        break;

      default:
        break;
    }
    this.setState({ newform, [name]: value });
  };



   handleSubmit = async (event) => {
    // console.log(this.state)
    event.preventDefault();
   
    if (this.formValid(this.state) && this.state.location_id !== "") {
      const formdata = new FormData() 
      if(this.state.file !== null)
      {
        formdata.append('image', this.state.file);
      }
      formdata.append('name', this.state.name);
      formdata.append('content', this.state.content);
      formdata.append('template_link_id', this.state.template_link_id);
      formdata.append('account_id', this.state.account_id);
      formdata.append('location_id', this.state.location_id);
      formdata.append('social_id', this.state.social_idd);


      let data = await CreateTemplate(formdata)
      // console.log(data)
      if(data.success === true){
        //debugge
        this.setState({message:'',bgcolor:'',location_id:''})
        
      document.getElementById("add-Location").reset();
      this.setState({startDate:''})
        await createNotification('success',data.message);
      }
      else
      {
        await createNotification('error',data.message);
      }
    } else {
    
      // console.log("Form is invalid");
    }
  };




  handleFile = async (event) =>{

      let file = event.target.files[0];
      // console.log(file);
      this.setState({file:file})
    
      
  }

  selectChangeCompnay = selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({location_id:this.state.selectedOption.value})
      //console.log(this.state.company_id)
    );
  };



  selectChangeLink = selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({template_link_id:this.state.selectedOption.value},()=>{console.log(this.state)})
      //console.log(this.state.company_id)
    );
  };


  selectChangeAccount = selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({account_id:this.state.selectedOption.value})
      //console.log(this.state.company_id)
    );
  };



  selectChangeSocial = selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({social_idd:this.state.selectedOption.value})
      //console.log(this.state.company_id)
    );
  };


  closemodal = () => {
    // debugger
    document.getElementById(this.modalshowid).style.display = "none";

  
    // document.getElementById(this.state.displayImage).value = "";
  };


  handleClickEdit = (event) => {
    // console.log(event);
    document.getElementById(this.modalshowid).style.display = "block";
  };

  
  render() {
    const { selectedOption } = this.state;

    return (
     
      <>
        <ModalDisplay
            id= {this.modalshowid}
            passedFunction={this.closemodal}
            dataid={this.state.modalId} 
            modalheader="View Template Details"
          />
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <div className="w3-container">
          <Header name="Add Template"/> 
          </div>
        

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-Location">
            <p style={{textAlign:'right'}}><span  className="w3-button w3-black " onClick={this.handleClickEdit}>Help</span></p>
            <p> <label>Select Location</label></p>
           

            <Select
                options={this.state.options}
                onChange={this.selectChangeCompnay}
              />

              <p>
                <label>Name</label>
                <input
                  className="w3-input"
                  name="name"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.name.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.name}
                    <br />
                  </span>
                )}
              </p>


              <p>
                <label>content</label>
                <input
                  className="w3-input"
                  name="content"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.content.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.content}
                    <br />
                  </span>
                )}
              </p>


              <p> <label>Link</label></p>
           

           <Select
               options={link}
               onChange={this.selectChangeLink}
             />


<p> <label>Account type</label></p>
           

           <Select
               options={account}
               onChange={this.selectChangeAccount}
             />



<p> <label>Social Link</label></p>
           

           <Select
               options={social}
               onChange={this.selectChangeSocial}
             />
              

  
              <p>
                <label>File</label>
                <input
                  className="w3-input"
                  type="file"
                  name="file"
                  onChange={this.handleFile}
                  noValidate
                />
                {this.state.formErrors.file.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.file}
                    <br />
                  </span>
                )}
              </p>



              <p>
                <button
                  className="w3-button w3-section w3-black w3-ripple"
                  disabled={this.state.name === '' || this.state.content === '' || this.state.location_id === '' || this.state.template_link_id === '' || this.state.account_id === '' || this.state.social_idd === ''}
                  onClick={this.handleSubmit}
                >
                  Add
                </button>
              </p>
            </form>
          </div>
          <NotificationContainer/>
        </div>
      
      </>
     
    );
  }
}
