import React from "react";
import "./reusable.css";
import { Link } from "react-router-dom";
import { NotificationManager} from 'react-notifications';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'


export default class RouterDom extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }
  render() {
    return {};
  }
}

const myAccFunc = (data, active) => {
  //debugger
  var x = document.getElementById(data);
  var y = document.getElementById(active);
  if (x.className.indexOf("w3-show") == -1) {
    x.className += " w3-show ";
    y.className += " w3-blue ";
  } else {
    x.className = x.className.replace(" w3-show", "");
    y.className = y.className.replace(" w3-blue", "");
  }
};

export const Sidemenu = (props) => {
  return (
    <div style={{cursor: 'pointer'}}>
      <p
        onClick={() => myAccFunc(props.id, props.icon)}
        className="w3-padding"
        id={props.icon}
      >
        <i className={props.icon}></i>  {props.name}{" "}
        <i className="fa fa-caret-down"></i>
      </p>
      <div
        id={props.id}
        className="w3-bar-block w3-hide w3-padding-medium w3-medium"
      >
        <Link className="w3-bar-item w3-button" to={`${props.submenulink1}`}>
          Add
        </Link>

        <Link className="w3-bar-item w3-button" to={props.submenulink2}>
          View
        </Link>
       {props.submenulink3 ? 
       
       <Link className="w3-bar-item w3-button" to={props.submenulink3}>
       View
     </Link>

       :''}
      </div>
    </div>
  );
};


export const ModalDisplay = (props) =>{
  //debugger

if(props.modalheader === 'Update Location'){
  return(
    <>
    
    <div id={props.id} className="w3-modal" style={{zIndex:'99'}}>
    <div className="w3-modal-content w3-card-4">
      <header className="w3-container w3-black"> 
        <span onClick={props.passedFunction} 
        className="w3-button w3-display-topright">&times;</span>
        <h2>{props.modalheader}</h2>
      </header>
    
      <div className="w3-container w3-row">
      <label>Company Name </label>
        <input type="text" style={{marginTop:'5px'}} id={`name ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' value={props.name} disabled/>
    
      </div>

      <div className="w3-container w3-row">
      <label>Location Name / title</label>
        <input type="text" style={{marginTop:'5px'}} name="modaltitle" id={`location ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter Location / Title' value={props.title}/>
    
      </div>

      <div className="w3-container w3-row">
        <label>Time Zone</label>
        <Select id={`time ${props.dataid}`} options={props.timezones} onChange={props.timeZoneChange} value={props.selectedTimeZone}/>
      </div>

      <div className="w3-container w3-row">
      <label>URL</label>
        <input type="text" style={{marginTop:'5px'}} name="url" id={`url ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter Url' value={props.url}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>Date</label>

      <DatePicker style={{marginTop:'5px'}} className="w3-input w3-border w3-margin-bottom" name="startDate" value={props.date}  onChange={props.datehandle}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>File</label>
      <input
                  className="w3-input"
                  type="file"
                  name="file"
                  id={`file ${props.dataid}`}
                  onChange={props.filedatachange}
                  noValidate
                />
    
      </div>

      <div className="w3-container w3-row">
        
        <ImageDisplay src1 = {props.file}/>
    
      </div>

      <footer className="w3-container w3-center">
      <button className="w3-button w3-section w3-black w3-ripple"  onClick={props.savedata}>Update</button>
      </footer>
    </div>
  </div>
  </>
  )
}








else if(props.modalheader === 'Update Company Name'){
  return(
    <>
    <div id={props.id} className="w3-modal" style={{zIndex:'99'}}>
    <div className="w3-modal-content w3-card-4">
      <header className="w3-container w3-black"> 
        <span onClick={props.passedFunction} 
        className="w3-button w3-display-topright">&times;</span>
        <h2>{props.modalheader}</h2>
      </header>
      
      <div className="w3-container w3-center w3-row">
        <input type="text" style={{marginTop:'25px'}} id={props.dataid} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter new name to change' value={props.name}/>
    
      </div>

      <footer className="w3-container w3-center">
      <button className="w3-button w3-section w3-black w3-ripple"  onClick={props.savedata}>Update</button>
      </footer>
    </div>
  </div>
  </>
  )
}



else if(props.modalheader === 'Update Header Text'){
  return(
    <>
    <div id={props.id} className="w3-modal" style={{zIndex:'99'}}>
    <div className="w3-modal-content w3-card-4">
      <header className="w3-container w3-black"> 
        <span onClick={props.passedFunction} 
        className="w3-button w3-display-topright">&times;</span>
        <h2>{props.modalheader}</h2>
      </header>
      
      <div className="w3-container w3-center w3-row">
        <input type="text" style={{marginTop:'25px'}} id={props.dataid} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter new Header Text' value={props.name}/>   
      </div>

      <footer className="w3-container w3-center">
      <button className="w3-button w3-section w3-black w3-ripple"  onClick={props.savedata}>Update</button>
      </footer>
    </div>
  </div>
  </>
  )
}

















else if(props.modalheader === 'Update Company Account'){
  return(
    <>
    <div id={props.id} className="w3-modal" style={{zIndex:'99'}}>
    <div className="w3-modal-content w3-card-4">
      <header className="w3-container w3-black"> 
        <span onClick={props.passedFunction} 
        className="w3-button w3-display-topright">&times;</span>
        <h2>{props.modalheader}</h2>
      </header>
      
      <div className="w3-container w3-center w3-row">
        <input type="text" style={{marginTop:'25px'}} id={`location_title ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter new name to change' value={props.name}/>
    
      </div>

      <footer className="w3-container w3-center">
      <button className="w3-button w3-section w3-black w3-ripple"  onClick={props.savedata}>Update</button>
      </footer>
    </div>
  </div>
  </>
  )
}




if(props.modalheader === 'Update Users'){
  return(
    <>
    
    <div id={props.id} className="w3-modal" style={{zIndex:'99'}}>
    <div className="w3-modal-content w3-card-4">
      <header className="w3-container w3-black"> 
        <span onClick={props.passedFunction} 
        className="w3-button w3-display-topright">&times;</span>
        <h2>{props.modalheader}</h2>
      </header>
    
      <div className="w3-container w3-row">
      <label>Company Name </label>
        <input type="text" style={{marginTop:'5px'}} id={`companyname ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' value={props.companyname} disabled/>
    
      </div>

      <div className="w3-container w3-row">
      <label>Username</label>
        <input type="text" style={{marginTop:'5px'}} name="modalusername" id={`username ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter Username' value={props.username}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>First Name</label>
        <input type="text" style={{marginTop:'5px'}} name="modalfname" id={`fname ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter First Name' value={props.fname}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>Last Name</label>

      <input type="text" style={{marginTop:'5px'}} name="modallname" id={`lname ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter Url' value={props.lname}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>Email</label>

      <input type="text" style={{marginTop:'5px'}} name="modalemail" id={`email ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter Email' value={props.email}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>Password</label>

      <input type="text" style={{marginTop:'5px'}} name="modalpassword" id={`password ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter New Password' value={props.password}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>File</label>
      <input
                  className="w3-input"
                  type="file"
                  name="file"
                  id={`file ${props.dataid}`}
                  onChange={props.filedatachange}
                  noValidate
                />
    
      </div>

      <div className="w3-container w3-row">
        
        <ImageDisplay src1 = {props.file}/>
    
      </div>

      <footer className="w3-container w3-center">
      <button className="w3-button w3-section w3-black w3-ripple"  onClick={props.savedata}>Update</button>
      </footer>
    </div>
  </div>
  </>
  )
}





if(props.modalheader === 'Update Template'){
  return(
    <>
    
    <div id={props.id} className="w3-modal" style={{zIndex:'99'}}>
    <div className="w3-modal-content w3-card-4">
      <header className="w3-container w3-black"> 
        <span onClick={props.passedFunction} 
        className="w3-button w3-display-topright">&times;</span>
        <h2>{props.modalheader}</h2>
      </header>

      <div className="w3-container w3-row">
      <label>Name</label>
        <input type="text" style={{marginTop:'5px'}} name="modalname" id={`name ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter name' value={props.name}/>
    
      </div>

      <div className="w3-container w3-row">
      <label>Content</label>
        <input type="text" style={{marginTop:'5px'}} name="modalcontent" id={`content ${props.dataid}`} className='w3-input w3-border w3-margin-bottom' onChange={props.inputdatachange} placeholder='Enter content' value={props.content}/>
    
      </div>

      

      <div className="w3-container w3-row">
      <label>File</label>
      <input
                  className="w3-input"
                  type="file"
                  name="file"
                  id={`file ${props.dataid}`}
                  onChange={props.filedatachange}
                  noValidate
                />
    
      </div>

      <div className="w3-container w3-row">
        
        <ImageDisplay src1 = {props.file}/>
    
      </div>

      <footer className="w3-container w3-center">
      <button className="w3-button w3-section w3-black w3-ripple"  onClick={props.savedata}>Update</button>
      </footer>
    </div>
  </div>
  </>
  )
}





if(props.modalheader === 'View Template Details'){
  return(
    <>
    
    <div id={props.id} className="w3-modal" style={{zIndex:'99'}}>
    <div className="w3-modal-content w3-card-4">
      <header className="w3-container w3-black"> 
        <span onClick={props.passedFunction} 
        className="w3-button w3-display-topright">&times;</span>
        <h2>{props.modalheader}</h2>
      </header>
<div style={{padding:'2%'}}> 
  <p><b>BreezeReview account template</b></p>

For google review: name(Google Review), content, image,template_link_id(2), account_id(1), location_id, social_id(1)

<p><b>BreezeAlert account template</b></p>

For appointment: name(Appt. Confirmation), content, image,template_link_id(3), account_id(2), location_id, social_id(5)
For 2 hours  auto appointment reminder: name(Reminder 2 Hours), content, template_link_id(3), account_id(2), location_id, social_id(5)
For 24 hours auto appointment reminder: name(Reminder 24 Hours), content, template_link_id(3), account_id(2), location_id, social_id(5)
<p><b>BreezeBack account template</b></p>

For google review: name(Google Review), content, image,template_link_id(2), account_id(3), location_id, social_id(1)

</div>
      <footer className="w3-container w3-center">
      <button className="w3-button w3-section w3-black w3-ripple"  onClick={props.passedFunction}>Close</button>
      </footer>
    </div>
  </div>
  </>
  )
}


  
}


export const Header = (props) =>{

  return(

    <header className="w3-container" style={{ paddingTop: "22px" }}>
    <h5>
      <b>{props.name}</b>
    </h5>
  </header>

  )

}

export const  createNotification = (type,message) => {
    //debugger
    let data = ''
      switch (type) {
        case 'info':
         data =  NotificationManager.info(message,"",1500);
          break;
          
        case 'success':
          data =   NotificationManager.success(message,"",1500);
          break;

        case 'warning':
          data =   NotificationManager.warning(message,"",1500);
          break;
          
        case 'error':
          data =    NotificationManager.error(message,"",1500);
          break;
      }
      return data
  };


  export const ImageDisplay = (props) =>{
    return <div className="w3-left"><img src = {props.src1} className="w3-round-small" style={{width:'150px',padding:'10px'}}></img> </div>
  }

  export const CheckBox = (props) =>{
    return( 
    <p>
    <input className="w3-check" type="checkbox" checked = {props.checkdata} name={props.name} onChange={props.handlechange} disabled = {props.disable}/>
      <label> {props.label}</label>
      </p>
    )
  }