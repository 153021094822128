import React  from "react";
import "./add-user.css";
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification } from "../reusable/reusable";
import { CreateUser,GetCompanyApi } from "../homeService";
import Select from 'react-select'


const emailReg = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const options = [
  { value: '1', label: 'Admin' },
  { value: '2', label: 'Manager' },
  { value: '3', label: 'Employee' }
]

export default class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {company_id: '', options:[], fname:'',lname:'',username: "",email:'',password:'', formErrors: { username: "",fname:"",file:"",lname:'',email:'',password:'' },file:null};
  }
  

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetCompanyApi();
    let companydata = await data.data.data;

    

    var output = await companydata.map(s => ({label:s.name,value:s.id}));
    //console.log(output);
    this.setState({ options : output});

  }


  formValid = ({ formErrors }) => {
    // console.log(formErrors);
    let valid = true;

    Object.values(formErrors).forEach((val) => {
      // console.log(val)
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      //console.log(this.state);
    });
    //console.log(this.state)
    let newform = this.state.formErrors;
    switch (name) {
      case "username":
        newform.username =  value.length > 0 ? "" : "User Name field cannot be empty";
        break;

        case "fname":
        newform.fname = value.length > 0 ? "" : "First Name field cannot be empty";
        break;

        case "lname":
          newform.lname = value.length > 0 ? "" : "Last Name field cannot be empty";
          break;

          case "email":
          newform.email = emailReg.test(value) &&  value.length > 0 ? "" : "Email not valid ";
          break;

          case "password":
          newform.password = value.length > 0 ? "" : "Password field cannot be empty";
          break;
        
        case "file":
          newform.file = value.length > 0 ? "" : "File not selected";
          break;

      default:
        break;
    }
    this.setState({ newform, [name]: value });
  };



   handleSubmit = async (event) => {
    // console.log(this.state)
    event.preventDefault();
   
    if (this.formValid(this.state) && this.state.company_name !== "") {
      const formdata = new FormData() 
      formdata.append('company_id', this.state.company_id);
      formdata.append('username', this.state.username);
      formdata.append('first_name', this.state.fname);
      formdata.append('last_name', this.state.lname);
      formdata.append('image', this.state.file);
      formdata.append('email', this.state.email);
      formdata.append('password', this.state.password);
      formdata.append('role_id', this.state.role_id);

      let data = await CreateUser(formdata)
      // console.log(data)
      if(data.success === true){
        //debugger
       // document.getElementById("add-company").reset();
      //  this.setState({company_name:''})
        
      document.getElementById("add-Location").reset();
      this.setState({startDate:''})
        await createNotification('success',data.message);
      }
      else
      {
        await createNotification('error',data.message);
      }
    } else {
    
      // console.log("Form is invalid");
    }
  };




  handleFile = async (event) =>{

      let file = event.target.files[0];
      // console.log(file);
      this.setState({file:file})
    
      
  }

  selectChange = selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({role_id:this.state.selectedOption.value})
      //console.log(this.state.company_id)
    );
  };

  selectChangeCompnay =  selectedOptionCompany => {
   
    this.setState(
      { selectedOptionCompany },
      () => 
      this.setState({company_id:this.state.selectedOptionCompany.value})
      // console.log(this.state.company_id)
    );
    
  };

  render() {
    const { selectedOption } = this.state;
    const { selectedOptionCompany } = this.state;
    return (
     
      <>
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <Header name="Add New User"/>

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-Location">
            <p> <label>Select Company</label></p>
           

            <Select
                options={this.state.options}
                onChange={this.selectChangeCompnay}
              />

              <p>
                <label>User Name</label>
                <input
                  className="w3-input"
                  name="username"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.username.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.username}
                    <br />
                  </span>
                )}
              </p>


              <p>
                <label>First Name</label>
                <input
                  className="w3-input"
                  name="fname"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.fname.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.fname}
                    <br />
                  </span>
                )}
              </p>

              <p>
                <label>Last Name</label>
                <input
                  className="w3-input"
                  name="lname"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.lname.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.lname}
                    <br />
                  </span>
                )}
              </p>

              <p>
                <label>Email</label>
                <input
                  className="w3-input"
                  name="email"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.email.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.email}
                    <br />
                  </span>
                )}
              </p>

              <p>
                <label>Password</label>
                <input
                  className="w3-input"
                  name="password"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.password.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.password}
                    <br />
                  </span>
                )}
              </p>

             
                <label>Select Role</label>
                <Select options={options}  onChange={this.selectChange} value={selectedOption} />
  
              <p>
                <label>File</label>
                <input
                  className="w3-input"
                  type="file"
                  name="file"
                  onChange={this.handleFile}
                  noValidate
                />
                {this.state.formErrors.file.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.file}
                    <br />
                  </span>
                )}
              </p>



              <p>
                <button
                  className="w3-button w3-section w3-black w3-ripple"
                  disabled={this.state.username === '' || this.state.fname === '' || this.state.lname === '' || this.state.file === null || this.state.role_id === '' || this.state.password === '' || this.state.email === ''}
                  onClick={this.handleSubmit}
                >
                  Add Company
                </button>
              </p>
            </form>
          </div>
          <NotificationContainer/>
        </div>
      
      </>
     
    );
  }
}
