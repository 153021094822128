// export const anotherName = (name) =>{
// return{
//     type:'CHANGE_NAME',
//     payload:name
// }
// }
import { createNotification } from "../modules/home/components/reusable/reusable";
import API from "../modules/loginapi";

export const anotherName = (name,password)=>{

       return async(dispatch)=>{
         API.post("login", {
            email: name,
            password: password,
          })
            .then(function (response) {
             //  console.log(response.data.success);
              if (response.data.success != false) {
                localStorage.setItem("token", response.data.data.token);
                dispatch({type:'CHANGE_NAME',payload:response.data.data.token})
                // console.log(localStorage.getItem("token"));
               // history.push("/dasboard");
              } 
              else {
                 createNotification('error','Incorrect Username or Password');
              }
            })
            .catch(function (error) {
              //console.log(error);
            });
       }
}


export const logOut = ()=>{
  return async(dispatch)=>{
  localStorage.setItem("token", '');
  dispatch({type:'LOG_OUT',payload:''})
  }
}

