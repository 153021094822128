import React, { Fragment }  from "react";
import "./add-company-account.css";
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification,CheckBox } from "../reusable/reusable";
import { GetLocationApi,GetAccountsApi,CreateCompanyAccount } from "../homeService";
import Select from 'react-select'
export default class AddCompanyAccount extends React.Component {
  
  state = {
      options:[],
      company_id:'',
      BreezeReview:false,
      BreezeAlert:false,
      BreezeBack:false,
      BackCoupon: false,
      BackVip: false,
      BreezeBook: false,
      reviewdisable:true,
      alertdisable:true,
      backdisable:true,
      coupondisable:true,
      vipdisable:true,
      bookdisable: true
    }

  async componentDidMount() {
    let data = await GetLocationApi();
    let companydata = await data.data.data;
    var output = await companydata.map(s => ({label:s.title,value:s.id}));
    this.setState({ options : output});
  }

  forceUpdateHandler(){
    this.forceUpdate();
  };

  handleChange = async (event) => {
    event.persist();
   if(event.target.type == 'checkbox')
   {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
   }
   if(event.target.name == 'BreezeReview'){

    let data = await CreateCompanyAccount(this.state.company_id,1)
      if(data.success == true){
        await createNotification('success',data.message);
        this.setState({reviewdisable:true})
        this.forceUpdateHandler();
      }
      else
      {
        await createNotification('error',data.message);
      }
   }

   if(event.target.name == 'BreezeAlert'){

    let data = await CreateCompanyAccount(this.state.company_id,2)
      if(data.success == true){
        await createNotification('success',data.message);
        this.setState({alertdisable:true})
      }
      else
      {
        await createNotification('error',data.message);
      }
   }

   if(event.target.name == 'BreezeBack'){

    let data = await CreateCompanyAccount(this.state.company_id,3)
      if(data.success == true){
        await createNotification('success',data.message);
        this.setState({backdisable:true})

      }
      else
      {
        await createNotification('error',data.message);
      }
   }

   if(event.target.name == 'BackCoupon'){

    let data = await CreateCompanyAccount(this.state.company_id,4)
      if(data.success == true){
        await createNotification('success',data.message);
        this.setState({coupondisable:true})

      }
      else
      {
        await createNotification('error',data.message);
      }
   }

   if(event.target.name == 'BackVip'){

    let data = await CreateCompanyAccount(this.state.company_id,5)
      if(data.success == true){
        await createNotification('success',data.message);
        this.setState({vipdisable:true})

      }
      else
      {
        await createNotification('error',data.message);
      }
   }

   if(event.target.name == 'BreezeBook'){

    let data = await CreateCompanyAccount(this.state.company_id,6)
      if(data.success == true){
        await createNotification('success',data.message);
        this.setState({bookdisable:true})

      }
      else
      {
        await createNotification('error',data.message);
      }
   }
  };


  checkdatafordisable = async (selectedOption) => {

    let AccountData = await GetAccountsApi(selectedOption.value);

    let accountsDetails = AccountData.data.data;
    let result = accountsDetails.map(a => a.type);
    this.setState({
      backdisable: result.includes("BreezeBack"),
      coupondisable: result.includes("BackCoupon"),
      vipdisable: result.includes("BackVIP"),
      reviewdisable: result.includes("BreezeReview"),
      alertdisable: result.includes("BreezeAlert"),
      bookdisable: result.includes("BreezeBook")
    });
  }

  selectChange = async selectedOption => {
    this.setState(
      { selectedOption },
      () => 
      this.setState({company_id:this.state.selectedOption.value})
    );
    this.setState({BreezeReview:false,BreezeAlert:false,BreezeBack:false,BackVip:false,BackCoupon:false,BreezeBook:false,reviewdisable:true,alertdisable:true,backdisable:true,coupondisable:true,vipdisable:true,bookdisable: true})
    this.checkdatafordisable(selectedOption);
    
  };


  render() {
    return (
      <>
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <Header name="Add Account Details" />

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-company">
              <p>
                <label>Select Location</label>
              </p>
              <Select
                options={this.state.options}
                onChange={this.selectChange}
              />
              <div className="w3-container w3-card-4" style={{marginBottom:'20px',marginTop:'10px'}}>
                <h2>Accounts</h2>
                <p>Checkbox Disbaled means that the account already have it</p>
                <p>To assign account to location, Just check the box</p>
                < CheckBox name="BreezeReview" checkdata={this.state.BreezeReview} label="BreezeReview" handlechange = {this.handleChange} disable = {this.state.reviewdisable}/>
                < CheckBox name="BreezeAlert" checkdata={this.state.BreezeAlert} label="BreezeAlert" handlechange = {this.handleChange} disable = {this.state.alertdisable}/>
                < CheckBox name="BreezeBack" checkdata={this.state.BreezeBack} label="BreezeBack" handlechange = {this.handleChange} disable = {this.state.backdisable}/>
                < CheckBox name="BackCoupon" checkdata={this.state.BackCoupon} label="BackCoupon" handlechange = {this.handleChange} disable = {this.state.coupondisable}/>
                < CheckBox name="BackVip" checkdata={this.state.BackVip} label="BackVip" handlechange = {this.handleChange} disable = {this.state.vipdisable}/>  
                < CheckBox name="BreezeBook" checkdata={this.state.BreezeBook} label="BreezeBook" handlechange = {this.handleChange} disable = {this.state.bookdisable}/>                 
              </div>
            </form>
          </div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}
