const iState = {
    token: localStorage.getItem("token")
}


const reducer = (state = iState,action)=>{
  if(action.type === 'CHANGE_NAME') // this is called when we click the login
  {
      //console.log("called")
      return{
          ...state,
          token:action.payload
      }
  }
  if(action.type === 'LOG_OUT') // this is called when we click the logout
  {
   // console.log("called log")
      return{
          ...state,
          token:action.payload
      }
  }
return state;
}

export default reducer;