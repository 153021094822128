import React, { Fragment }  from "react";
import "./add-company.css";
import {CreateCompany} from '../homeService';
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification } from "../reusable/reusable";


export default class AddCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = { company_name: "", formErrors: { company_name: "" }};
  }
 

  formValid = ({ formErrors }) => {
   
    let valid = true;

    Object.values(formErrors).forEach((val) => {
      // console.log(val)
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      //console.log(this.state);
    });
    //console.log(this.state)
    let newform = this.state.formErrors;
    switch (name) {
      case "company_name":
        newform.company_name = value.length > 0 ? "" : "Field Cannot be empty";
        break;
      default:
        break;
    }
    this.setState({ newform, [name]: value });
  };

   handleSubmit = async (event) => {
    
    event.preventDefault();
   
    if (this.formValid(this.state) && this.state.company_name != "") {
      // console.log("Form is valid");
      let data = await CreateCompany(this.state.company_name)
      // console.log(data)
      if(data.success === true){
        //debugger
        document.getElementById("add-company").reset();
        this.setState({company_name:''})
        await createNotification('success',data.message);
      }
      else
      {
        await createNotification('error',data.message);
      }
    } else {
    
      // console.log("Form is invalid");
    }
  };

  render() {
    return (
     
      <>
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <Header name="Add New Company"/>

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-company">
              <p>
                <label>Company Name</label>
                <input
                  className="w3-input"
                  name="company_name"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.company_name.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.company_name}
                    <br />
                  </span>
                )}
              </p>

              <p>
                <button
                  className="w3-button w3-section w3-black w3-ripple"
                  disabled={!this.state.company_name}
                  onClick={this.handleSubmit}
                >
                  Add Company
                </button>
              </p>
            </form>
          </div>
          <NotificationContainer/>
        </div>
      
      </>
     
    );
  }
}
