import React  from "react";
import "./add-locationsocial.css";
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification } from "../reusable/reusable";
import { GetLocationApi,CreateSocial } from "../homeService";

import Select from 'react-select'

const social = [
  { value: '1', label: 'Google' },
  { value: '2', label: 'Facebook'},
  { value: '3', label: 'Yelp'},
  { value: '4', label: 'Trustpilot'},
  { value: '6', label: 'BBB'},
  { value: '7', label: 'Capterra'},
  { value: '8', label: 'YellowPages'},
  { value: '9', label: 'Custom'},
  { value: '5', label: 'None'}
]


export default class AddSocialLocation extends React.Component {

  state = { 
    options: [],
    company_id:'',
    social_id:'', 
    formErrors: { social_id: "",selectedOption:"" },
    selectedOption: null
  }

  async componentDidMount() {
    let data = await GetLocationApi();
    let companydata = await data.data.data;
    var output = await companydata.map(s => ({label:s.title,value:s.id}));
    this.setState({ options : output});
  }

   handleSubmit = async (event) => {
    
    event.preventDefault();
   
      let data = await CreateSocial(this.state.company_id,this.state.social_id)
      if(data.success == true){       
      document.getElementById("add-Location").reset();
      this.setState({startDate:''})
        await createNotification('success',data.message);
      }
      else
      {
        await createNotification('error',data.message);
      }
  };

  selectChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => 
      this.setState({company_id:this.state.selectedOption.value})
    );
  };

  selectChangeSocial = selectedOption1 => {
    this.setState(
      { selectedOption1 },
      () => 
      this.setState({social_id:this.state.selectedOption1.value})
    );
  };

  render() {
    const { selectedOption } = this.state;
    const { selectedOption1 } = this.state;
    return (
     
      <>
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <Header name="Add Social Location"/>

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-Location">

            <p>
                <label>Select Location</label>
              </p>
              <Select options={this.state.options} onChange={this.selectChange} value={selectedOption}/>

              <p>
                <label>Select Target</label>
              </p>
              <Select options={social} onChange={this.selectChangeSocial} value={selectedOption1}/>       
              <p>
                <button
                  className="w3-button w3-section w3-black w3-ripple"
                  disabled={this.state.placeid == '' || this.state.company_id == ''}
                  onClick={this.handleSubmit}
                >
                  Add Social Link
                </button>
              </p>
            </form>
          </div>
          <NotificationContainer/>
        </div>
      
      </>
     
    );
  }
}
