import React from "react";
import MaterialTable from "material-table";
import { DeleteCustomTiny, GetCustomTiny } from "../homeService";
import { Header,createNotification } from "../reusable/reusable";
import { NotificationContainer } from "react-notifications";

export default class ViewCustomTiny extends React.Component {

  state = {};

  async componentDidMount() {
    let data = await GetCustomTiny();
    let companydata = data.data.data;
    this.setState({ companydata });
  }

  handleClickDelete = async (event) => {
    let deleteddata = await DeleteCustomTiny(event.id);
    let data = await GetCustomTiny();
    let companydata = data.data.data;
    this.setState({ companydata });
    await createNotification("warning", deleteddata.message);
  };

  render() {
    return (
      <>
       <NotificationContainer />
      <div
        className="w3-main"
        style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
      >
       <Header name="View Custom TinyURL"/>
          <MaterialTable
            title=""
            columns={[
              { title: "ID", field: "id" },
              { title: "Location", field: "location_title" },
              { title: "Tiny URL", field: "url" }
            ]}
            data={this.state.companydata}
            actions={[
              (rowData) => ({
                icon: "delete",
                tooltip: "Delete URL",
                onClick: (event, rowData) => this.handleClickDelete(rowData),
                disabled: rowData.birthYear < 2000,
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </div>     
      </>
    );
  }
}
