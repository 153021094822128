import React from "react";
import SideNav from "../components/side-nav/side-nav";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import Dashboard from './components/dashboard/dashboard';
import AddCompany from "../components/add-company/add-company";
import ViewCompany from "./view-company/view-company";
import Login from "../../login/components/login/login";
import Dashboard from "./dashboard/dashboard";
import AddLocation from "./location/add-location";
import ViewLocation from "./location/view-location";
import AddCompanyAccount from "./company-account/add-company-account";
import ViewCompanyAccount from "./company-account/view-company-account";
import ViewUser from "./user/view-users";
import AddUser from "./user/add-user";
import AddLocationUsers from "./location users/add-location-users";
import ViewLocationUsers from "./location users/view-location-users";
import ViewTwilio from "./twilio/view-twilio";
import AddTwilio from "./twilio/add-twilio";
import AddGmb from "./gmb/add-gmb";
import ViewGmb from "./gmb/view-gmb";
import AddTiny from "./tinyurl/add-tiny";
import ViewTiny from "./tinyurl/view-tiny";
import ViewReviewLanding from "./reviewlanding/view-reviewLanding";
import AddReviewLandingPage from "./reviewlanding/add-reviewLanding";
import ViewAlertLanding from "./alertlanding/view-alertLanding";
import AddAlertLandingPage from "./alertlanding/add-alertLanding";
import ViewBackLanding from "./backlanding/view-backLanding";
import AddBackLandingPage from "./backlanding/add-backLanding";

import {connect} from 'react-redux';
import AddTemplate from "./template/add-template";
import ViewTemplate from "./template/view-template";
import ViewFacebook from "./facebook/view-facebook";
import AddFacebook from "./facebook/add-facebook";
import ViewTinyFacebook from "./tinyurl facebook/view-tinyfacebook";
import AddTinyFacebook from "./tinyurl facebook/add-tinyfacebook";
import ViewLocationSocial from "./Location Social/view-locationsocial";
import AddSocialLocation from "./Location Social/add-locationsocial";
import ViewYelp from "./yelp/view-yelp";
import AddYelp from "./yelp/add-yelp";
import ViewTinyYelp from "./tinyurl yelp/view-tinyurl";
import AddTinyYelp from "./tinyurl yelp/add-tinyurl";
import ViewCapterra from "./capterra/view-capterra";
import AddCapterra from "./capterra/add-capterra";
import ViewTinyCapterra from "./tinyurl capterra/view-tinycapterra";
import AddTinyCapterra from "./tinyurl capterra/add-tinycapterra";
import ViewOther from "./bbb/view-bbb";
import AddOther from "./bbb/add-bbb";
import ViewTinyOther from "./tinyurl BBB/view-tinybbb";
import AddTinyOther from "./tinyurl BBB/add-tinybbb";
import Viewbbb from "./bbb/view-bbb";
import Addbbb from "./bbb/add-bbb";
import ViewTinybbb from "./tinyurl BBB/view-tinybbb";
import AddTinybbb from "./tinyurl BBB/add-tinybbb";
import AddTrustPilot from './trustpilot/add-trustpilot';
import ViewTrustPilot from './trustpilot/view-trustpilot';
import AddTrustPilotTiny from './tinyurl-trustpilot/add-tinyurl';
import ViewTrustPilotTiny from './tinyurl-trustpilot/view-tinyurl';
import AddYellowPages from './yellowpages/add-yellowpages';
import ViewYellowPages from './yellowpages/view-yellowpages';
import AddYellowPagesTiny from './tinyurl-yellopages/add-tinyurl';
import ViewYellowPagesTiny from './tinyurl-yellopages/view-tinyurl';
import AddBackHeader from './backHeader/addBackHeader';
import ViewBackHeader from './backHeader/viewBackHeader';
import AddCustom from './customUrl/add-custom';
import ViewCustom from './customUrl/view-custom';
import AddCustomTiny from './tinyurl-custom/add-tinyurl';
import ViewCustomTiny from './tinyurl-custom/view-tinyurl';


 class Home extends React.Component {
  constructor(props) {
   // console.log(props)
    super(props);
  }

  render() {

     if(!this.props.myname)
     {
        return (
       
            <>
            
              <Router>
                  
                <Switch>
                  <Route exact path="/" component={Login} />
                  <Route path="*" component={Login}/>
                  <Route exact path="/login" component={Login} />
                </Switch>
              </Router>
            </>
          );
     }
     else
     {
        return (
       
            <>
            {/* <h1 style={{marginTop:'25px'}}>Hi {this.props.myname}</h1> */}
              <Router>                
              <SideNav/>
                <Switch>
                  <Route exact path="/add_company" component={AddCompany} />
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/home" component={Home} />
                  <Route exact path="/view_company" component={ViewCompany} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/create_location" component={AddLocation} />
                  <Route exact path="/view_location" component={ViewLocation} />
                  <Route exact path="/add_company_account" component={AddCompanyAccount} />
                  <Route exact path="/view_company_account" component={ViewCompanyAccount} />
                  <Route exact path="/add_users" component={AddUser} />
                  <Route exact path="/view_users" component={ViewUser} />
                  <Route exact path="/add_location_users" component={AddLocationUsers} />
                  <Route exact path="/view_location_users" component={ViewLocationUsers} />
                  <Route exact path="/view_twilio" component={ViewTwilio} />
                  <Route exact path="/add_twilio" component={AddTwilio} />.
                  <Route exact path="/add_gmb" component={AddGmb} />
                  <Route exact path="/view_gmb" component={ViewGmb} />
                  <Route exact path="/add_tinyurl" component={AddTiny} />
                  <Route exact path="/view_tinyurl" component={ViewTiny} />
                  <Route exact path="/view_reviewLanding" component={ViewReviewLanding} />
                  <Route exact path="/add_reviewLanding" component={AddReviewLandingPage} />
                  <Route exact path="/view_alertLanding" component={ViewAlertLanding} />
                  <Route exact path="/add_alertLanding" component={AddAlertLandingPage} />
                  <Route exact path="/view_backLanding" component={ViewBackLanding} />
                  <Route exact path="/add_backLanding" component={AddBackLandingPage} />
                  <Route exact path="/add_template" component={AddTemplate} />
                  <Route exact path="/view_template" component={ViewTemplate} />
                  <Route exact path="/view_facebook" component={ViewFacebook} />
                  <Route exact path="/add_facebook" component={AddFacebook} />
                  <Route exact path="/view_tinyfacebook" component={ViewTinyFacebook} />
                  <Route exact path="/add_tinyfacebook" component={AddTinyFacebook} />

                  <Route exact path="/view_social" component={ViewLocationSocial} />
                  <Route exact path="/add_social" component={AddSocialLocation} />

                  <Route exact path="/view_yelp" component={ViewYelp} />
                  <Route exact path="/add_yelp" component={AddYelp} />
                
                  <Route exact path="/view_tinyyelp" component={ViewTinyYelp} />
                  <Route exact path="/add_tinyyelp" component={AddTinyYelp} />

                  <Route exact path="/view_capterra" component={ViewCapterra} />
                  <Route exact path="/add_capterra" component={AddCapterra} />

                  <Route exact path="/view_tinycapterra" component={ViewTinyCapterra} />
                  <Route exact path="/add_tinycapterra" component={AddTinyCapterra} />

                  <Route exact path="/view_bbb" component={Viewbbb} />
                  <Route exact path="/add_bbb" component={Addbbb} />

                  
                  <Route exact path="/view_tinybbb" component={ViewTinybbb} />
                  <Route exact path="/add_tinybbb" component={AddTinybbb} />

                  <Route exact path="/view_trustpilot" component={ViewTrustPilot} />
                  <Route exact path="/add_trustpilot" component={AddTrustPilot} />

                  <Route exact path="/view_tinytrustpilot" component={ViewTrustPilotTiny} />
                  <Route exact path="/add_tinytrustpilot" component={AddTrustPilotTiny} />

                  <Route exact path="/view_yellowpages" component={ViewYellowPages} />
                  <Route exact path="/add_yellowpages" component={AddYellowPages} />

                  <Route exact path="/view_tinyyellowpages" component={ViewYellowPagesTiny} />
                  <Route exact path="/add_tinyyellowpages" component={AddYellowPagesTiny} />

                  <Route exact path="/view_custom" component={ViewCustom} />
                  <Route exact path="/add_custom" component={AddCustom} />

                  <Route exact path="/view_customtiny" component={ViewCustomTiny} />
                  <Route exact path="/add_customtiny" component={AddCustomTiny} />

                  <Route exact path="/add_backreview_header" render={(props) => (<AddBackHeader {...props} account='backReviews' accountName='BackReview' />)} />
                  <Route exact path="/view_backreview_header" render={(props) => (<ViewBackHeader {...props} account='backReviews' accountName='BackReview' />)} />

                  <Route exact path="/add_backcoupon_header" render={(props) => (<AddBackHeader {...props} account='backCoupons' accountName='BackCoupon' />)} />
                  <Route exact path="/view_backcoupon_header" render={(props) => (<ViewBackHeader {...props} account='backCoupons' accountName='BackCoupon' />)} />

                  <Route exact path="/add_backvip_header" render={(props) => (<AddBackHeader {...props} account='backVips' accountName='BackVIP' />)} />
                  <Route exact path="/view_backvip_header" render={(props) => (<ViewBackHeader {...props} account='backVips' accountName='BackVIP' />)} />

                </Switch>
              </Router>
            </>
          );
     }
  }
}



const mapStateToProps = (state)=>{
return{
  myname:state.token
}
}


export default connect(mapStateToProps) (Home);