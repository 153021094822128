import React  from "react";
import "./add-bbb.css";
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification } from "../reusable/reusable";
import { GetLocationApi,CreateOther } from "../homeService";

import Select from 'react-select'

export default class Addbbb extends React.Component {
  constructor(props) {
    super(props);
    this.state = { options: [],company_id:'',placeid:'', formErrors: { placeid: "",selectedOption:"" },selectedOption: null};
  }

  

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetLocationApi();
    let companydata = await data.data.data;

    var output = await companydata.map(s => ({label:s.title,value:s.id}));
    //console.log(output);
    this.setState({ options : output});
    //this.setState({ companydata });
  }


  formValid = ({ formErrors }) => {
    // console.log(formErrors);
    let valid = true;

    Object.values(formErrors).forEach((val) => {
      // console.log(val)
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      //console.log(this.state);
    });
    //console.log(this.state)
    let newform = this.state.formErrors;
    switch (name) {
      case "placeid":
        newform.placeid = value.length > 0 ? "" : "Place Id field cannot be empty";
        break;

      
      default:
        break;
    }
    this.setState({ newform, [name]: value });
  };



   handleSubmit = async (event) => {
    
    event.preventDefault();
   
    if (this.formValid(this.state) && this.state.company_id != "") {
      let data = await CreateOther(this.state.company_id,this.state.placeid)
      // console.log(data)
      if(data.success == true){
        //debugger
       // document.getElementById("add-company").reset();
      //  this.setState({company_name:''})
        
      document.getElementById("add-Location").reset();
      this.setState({startDate:''})
        await createNotification('success',data.message);
      }
      else
      {
        await createNotification('error',data.message);
      }
    } else {
    
      // console.log("Form is invalid");
    }
  };

  selectChange = selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({company_id:this.state.selectedOption.value})
      //console.log(this.state.company_id)
    );
  };



  render() {
    const { selectedOption } = this.state;
    return (
     
      <>
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <Header name="Add BBB Link"/>

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-Location">

            <p>
                <label>Select Location</label>
              </p>
              <Select options={this.state.options} onChange={this.selectChange} value={selectedOption}/>


              <p>
                <label>Location URL</label>
                <input
                  className="w3-input"
                  name="placeid"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.placeid.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.placeid}
                    <br />
                  </span>
                )}
              </p>


             

              <p>
                <button
                  className="w3-button w3-section w3-black w3-ripple"
                  disabled={this.state.placeid == '' || this.state.company_id == ''}
                  onClick={this.handleSubmit}
                >
                  Add BBB
                </button>
              </p>
            </form>
          </div>
          <NotificationContainer/>
        </div>
      
      </>
     
    );
  }
}
