import React from "react";
import MaterialTable from "material-table";
import { deleteBackHeader, updateBackHeader, getBackHeader } from "../homeService";
import { ModalDisplay,Header,createNotification } from "../reusable/reusable";
import { NotificationContainer } from "react-notifications";

export default class ViewBackHeader extends React.Component{
    
    state={
        headers: [],
        editeddata: '',
        modalname: '',
        modalId: ''
    }

    modalshowid = this.props.accountName+'_Modal';

    async componentDidMount() {
        let data = await getBackHeader(this.props.account);
        if(data.data.success){
            let headers = data.data.data;
            this.setState({ headers });
        }
        else {
            this.setState({headers:[]});
            createNotification("error", data.data.message);
        }
      }
    
    async componentDidUpdate(prevProps){
        if(prevProps.account !== this.props.account){
            let data = await getBackHeader(this.props.account);
            if(data.data.success){
                let headers = data.data.data;
                this.setState({ headers });
            }
            else {
                this.setState({headers:[]});
                createNotification("error", data.data.message);
            }
        }
    }
    
    handleEditForm = (event) => {
        this.setState({ modalname: event.target.value, modalId: event.target.id });
    };
    
    editdata = async () => {
        let Updateddata = await updateBackHeader(
            this.props.account,
            this.state.modalId,
            this.state.modalname
        );
        let data = await getBackHeader(this.props.account);
        let headers = data.data.data;
        this.setState({ headers });
        this.closemodal();
        await createNotification("success", Updateddata.message);
    };

    handleClickDelete = async (event) => {
        let deleteddata = await deleteBackHeader(this.props.account,event.id);
        let data = await getBackHeader(this.props.account);
        let headers = data.data.data;
        this.setState({ headers });
        await createNotification("warning", deleteddata.message);
    };

    handleClickEdit = (event) => {
        this.setState({ modalId: event.id, modalname: event.header });
        document.getElementById(this.modalshowid).style.display = "block";
    };

    closemodal = () => {
        document.getElementById(this.modalshowid).style.display = "none";
        document.getElementById(this.state.modalId).value = "";
    };
    
    render(){
        return(
            <>
                <NotificationContainer />
                <div
                    className="w3-main"
                    style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
                >
                <Header name={'View '+this.props.accountName+' Headers'}/>
                    <ModalDisplay
                        id= {this.modalshowid}
                        passedFunction={this.closemodal}
                        savedata={this.editdata}
                        inputdatachange={this.handleEditForm}
                        name={this.state.modalname}
                        dataid={this.state.modalId}
                        modalheader="Update Header Text"
                    />

                    <MaterialTable
                        title=""
                        columns={[
                        { title: "ID", field: "id" },
                        { title: "Location", field: "location" },
                        { title: "Header", field: "header" }
                        ]}
                        data={this.state.headers}
                        actions={[
                        {
                            icon: "edit",
                            tooltip: "Edit Header",
                            onClick: (event, rowData) => this.handleClickEdit(rowData),
                        },
                        (rowData) => ({
                            icon: "delete",
                            tooltip: "Delete Header",
                            onClick: (event, rowData) => this.handleClickDelete(rowData),
                            disabled: false
                        }),
                        ]}
                        options={{
                        actionsColumnIndex: -1,
                        }}
                    />
                    </div>
      
            </>
        )
    }
}

