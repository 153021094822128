import React from "react";
import "./view-capterra.css";
import MaterialTable from "material-table";
import { DeleteCapterra, GetCapterra } from "../homeService";
import { Header,createNotification } from "../reusable/reusable";
import { NotificationContainer } from "react-notifications";

export default class ViewCapterra extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetCapterra();
    let companydata = data.data.data;
   // console.log(companydata)
    this.setState({ companydata });
  }



  handleClickDelete = async (event) => {
   // console.log(event.id);
    let deleteddata = await DeleteCapterra(event.id);
    let data = await GetCapterra();
    let companydata = data.data.data;
    //console.log(companydata)
    this.setState({ companydata });
    await createNotification("warning", deleteddata.message);
  };



  render() {
    return (
      <>
       <NotificationContainer />
      <div
        className="w3-main"
        style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
      >
       <Header name="View Capterra Details"/>
          <MaterialTable
            title=""
            columns={[
              { title: "ID", field: "id" },
              { title: "Location", field: "location_title" },
              { title: "Place Id", field: "cid" }
            ]}
            data={this.state.companydata}
            actions={[
          

              (rowData) => ({
                icon: "delete",
                tooltip: "Delete User",
                onClick: (event, rowData) => this.handleClickDelete(rowData),
                disabled: rowData.birthYear < 2000,
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </div>
      
      </>
    );
  }
}
