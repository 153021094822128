import React from "react";
import "./view-location.css";
import MaterialTable from "material-table";
import { DeleteLocation, UpdateLocation, GetLocationApi } from "../homeService";
import { ModalDisplay,Header,createNotification } from "../reusable/reusable";
import { NotificationContainer } from "react-notifications";
import moment from 'moment'

const timeZones = [
  { value: 'America/Edmonton', label: 'America/Edmonton' },
  { value: 'America/Halifax', label: 'America/Halifax' },
  { value: 'America/Moncton', label: 'America/Moncton' },
  { value: 'America/Montreal', label: 'America/Montreal' },
  { value: 'America/St_Johns', label: 'America/St_Johns' },
  { value: 'America/Toronto', label: 'America/Toronto' },
  { value: 'America/Regina', label: 'America/Regina' },
  { value: 'America/Thunder_Bay', label: 'America/Thunder_Bay' },
  { value: 'America/Vancouver', label: 'America/Vancouver' },
  { value: 'America/Winnipeg', label: 'America/Winnipeg' },
  { value: 'America/Whitehorse', label: 'America/Whitehorse' },
  { value: 'America/Yellowknife', label: 'America/Yellowknife' },
]

export default class ViewLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { company: [], editeddata: "", modalname: "",modaltitle:'', modalId: "",timeZone:'',selectedOption: null,selectedTimeZone:null,startDate: '',file:null,url:'',location_name: "" ,displayImage :''};
    this.modalshowid = '02';
  }

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetLocationApi();
    let companydata = data.data.data;
   // console.log(companydata)
    this.setState({ companydata });
  }

  handleEditForm = (event) => {
    //console.log(event.target.name,event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  editdata = async () => {
    // console.log("called");
    // console.log(this.state.modalId)

    const formdata = new FormData() 
    
    formdata.append('logo', this.state.file);
    formdata.append('title', this.state.modaltitle);
    formdata.append('url', this.state.url);
    formdata.append('start_date', this.state.startDate);
    formdata.append('timezones', this.state.timeZone);
   

    let Updateddata = await UpdateLocation(
      this.state.modalId,
      formdata
    );
    let data = await GetLocationApi();
    //console.log(data);
    let companydata = data.data.data;
    this.setState({ companydata });
    this.closemodal();
    await createNotification("success", Updateddata.message);
  };

  handleClickDelete = async (event) => {
   // console.log(event.id);
    let deleteddata = await DeleteLocation(event.id);
    let data = await GetLocationApi();
    let companydata = data.data.data;
    //console.log(companydata)
    this.setState({ companydata });
    await createNotification("warning", deleteddata.message);
  };

  handleClickEdit = (event) => {
    console.log(event);
    this.setState({ 
      modalId: event.id, 
      modalname: event.company_name,
      modaltitle:event.title,
      url:event.url,
      startDate:event.start_date,
      displayImage:event.logo,
      timeZone: event.timezones, 
      selectedTimeZone: {label:event.timezones,value:event.timezones} });
    document.getElementById(this.modalshowid).style.display = "block";
  };

 

  closemodal = () => {
    // debugger
    document.getElementById(this.modalshowid).style.display = "none";
    document.getElementById(`name ${this.state.modalId}`).value = "";
    document.getElementById(`file ${this.state.modalId}`).value = "";
    document.getElementById(`url ${this.state.modalId}`).value = "";
    document.getElementById(`file ${this.state.modalId}`).value = "";
    document.getElementById(`time ${this.state.modalId}`).value = null;
    // document.getElementById(this.state.displayImage).value = "";
  };

  handleFile = async (event) =>{

    let file = event.target.files[0];
    //console.log(file);
    this.setState({file:file})
  
    
}

handleDateChange = async (date) => {
  
  // var a =  date.dateFormat('m/d/Y');
   //console.log(date);
   var b = moment(date).format("M/D/Y");
  // console.log(b);
  this.setState({
    startDate: b
  });

};

timeZoneHandler = selectedTimeZone => {
  this.setState(
    { selectedTimeZone },
    () => 
    this.setState({timeZone:this.state.selectedTimeZone.value})
  );
};


handleSwitchEdit = async (event) => {
  //console.log(event);
  let active = ''
  const formdata = new FormData() 
  if(event.active == 1){
    active = 0;
  }
  else{
    active = 1
  }
  formdata.append('title', event.title);
  formdata.append('url', event.url);
  formdata.append('start_date', event.start_date);
  formdata.append('active', active);
 


  let Updateddata = await UpdateLocation(
    event.id,
    formdata
  );
  let data = await GetLocationApi();
  //console.log(data);
  let companydata = data.data.data;
  this.setState({ companydata });
  await createNotification("success", Updateddata.message);
  //this.setState({ modalId: event.id, modalname: event.company_name,modaltitle:event.title,url:event.url,startDate:event.start_date,displayImage:event.logo },()=>{ console.log(this.state);});
 // document.getElementById(this.modalshowid).style.display = "block";
};


  render() {
    return (
      <>
       <NotificationContainer />
      <div
        className="w3-main"
        style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
      >
       <Header name="View Location"/>
          <ModalDisplay
            id= {this.modalshowid}
            passedFunction={this.closemodal}
            savedata={this.editdata}
            inputdatachange= {this.handleEditForm}
            filedatachange = {this.handleFile}
            datehandle = {this.handleDateChange}
            name={this.state.modalname}
            dataid={this.state.modalId}
            url= {this.state.url}
            title = {this.state.modaltitle}
            file = {this.state.displayImage}
            date = {this.state.startDate}
            timezones = {timeZones}
            selectedTimeZone= {this.state.selectedTimeZone}
            timeZoneChange = {this.timeZoneHandler}
            modalheader="Update Location"
          />

          <MaterialTable
            title=""
            columns={[
              { title: "ID", field: "id" },
              { title: "Name", field: "company_name" },
              { title: "Title", field: "title" },
              { title: "Status", field: "active",render:rowData=>{
                return(
                rowData.active == '1' ? <p style={{color:'green',fontWeight:'bold'}}>Active</p>:
                <p style={{color:'red',fontWeight:'bold'}}>Inactive</p>
                )
              },
            }
            ]}
            data={this.state.companydata}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit User",
                onClick: (event, rowData) => this.handleClickEdit(rowData),
              },

              

            
              (rowData) => ({
                icon: (rowData.active) == '1'? 'close' : 'history',
                tooltip:  (rowData.active) == '1'? 'Stop' : 'Reactivate',
                onClick: (event, rowData) => this.handleSwitchEdit(rowData),
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </div>
      
      </>
    );
  }
}
