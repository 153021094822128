import axios from "axios";

export default function api(){
  return axios.create({
    baseURL: `https://apil.breezereview.com/api`,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });
} 
