import React from 'react'
import './addBackHeader.css'

import {NotificationContainer} from 'react-notifications';
import { Header,createNotification } from "../reusable/reusable";
import { GetLocationApi,createBackHeader } from "../homeService";

import Select from 'react-select'

export default class AddBackHeader extends React.Component{
    
    state={
        options: [],
        locationId: '',
        selectedOption: null,
        formErrors: {header:'',selectedOption:''},
        header:''
    }

    selectChange = (selectedOption) => {
        this.setState(
            { selectedOption },
            () => 
            this.setState({locationId:this.state.selectedOption.value}));
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
        });
        let newform = this.state.formErrors;
        switch (name) {
          case "header":
            newform.header = value.length > 0 ? "" : "Header Text cannot be empty";
            break;
    
          
          default:
            break;
        }
        this.setState({ newform, [name]: value });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        if (this.formValid(this.state) && this.state.locationId != "") {
            let data = await createBackHeader(this.props.account,this.state.locationId,this.state.header);
            if(data.success == true){            
            document.getElementById(this.props.accountName+"-add-header").reset();
            this.setState({selectedOption:null})
              await createNotification('success',data.message);
            }
            else
            {
              await createNotification('error',data.message);
            }
          } else {          
            // console.log("Form is invalid");
          }
    }

    async componentDidMount(){
        let data = await GetLocationApi();
        let companydata = await data.data.data;
        var output = await companydata.map(s => ({label:s.title,value:s.id}));
        this.setState({ options : output});
    }

    formValid = ({ formErrors }) => {
        let valid = true;
        Object.values(formErrors).forEach((val) => {
          val.length > 0 && (valid = false);
        });
        return valid;
      };
    
    render(){
        const { selectedOption } = this.state;

        return(
            <div className="w3-main" style={{ marginLeft: "300px", marginTop: "43px" }}>
            <Header name={'Add '+ this.props.accountName +' Header'} />

            <div className="w3-container  w3-margin-top">
                <form className="w3-container w3-card-4" id={this.props.accountName+"-add-header"}>
                    
                    <p><label>Select Location</label></p>
                    <Select
                        options={this.state.options}
                        onChange={this.selectChange}
                        value = {selectedOption}
                    />

                    <p>
                        <label>Header Text</label>
                        <input
                            className="w3-input"
                            name="header"
                            type="text"
                            onChange={this.handleChange}
                            noValidate
                        />
                        {this.state.formErrors.header.length > 0 && (
                            <span className="" style={{ color: "red" }}>
                                {this.state.formErrors.header}
                                <br />
                            </span>
                        )}
                    </p>

                    <p>
                        <button
                        className="w3-button w3-section w3-black w3-ripple"
                        disabled={this.state.header == '' || this.state.locationId == ''}
                        onClick={this.handleSubmit}
                        >
                        Add Header
                        </button>
                    </p>
                </form>
            </div>
            <NotificationContainer/>
            </div>
        )
    }
}
