import React from "react";
import "./add-location-users.css";
import {CreateCompany} from '../homeService';
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification } from "../reusable/reusable";
import { GetLocationApi,GetUsersDataApi,CreateLocationUsers } from "../homeService";
import Select from 'react-select'
export default class AddLocationUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {options:[],users:[],company_id:''};

  }


  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetLocationApi();
    let companydata = await data.data.data;

    var output = await companydata.map(s => ({label:s.title,value:s.id}));
    //console.log(output);
    this.setState({ options : output});


    let data2 = await GetUsersDataApi();
    let companydata2 = await data2.data.data;

    var output2 = await companydata2.map(s => ({label:s.email,value:s.id}));
    //console.log(output);
    this.setState({ users : output2});


    //this.setState({ companydata });
  }



  handleChange = async (event) => {
    event.persist();

    // console.log(event.target.name)
   

  };

  selectChangeLocation = async selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({location_id:this.state.selectedOption.value})
      // console.log(this.state.company_id)
    );
   
    
  };


  selectChangeUser = async selectedOption => {
    // console.log(selectedOption)
    this.setState(
      { selectedOption },
      () => 
      this.setState({user_id:this.state.selectedOption.value})
    );
   
    
  };

  handleSubmit = async (event) => {
    
    event.preventDefault();
   
    if (this.state.location_id !== '' && this.state.user_id != "" ) {
      // console.log("Form is valid");
      let data = await CreateLocationUsers(this.state.location_id,this.state.user_id)
      // console.log(data)
      if(data.success == true){
        //debugger
        document.getElementById("add-company").reset();
        this.setState({location_id:'',user_id:''})
        await createNotification('success',data.message);
      }
      else
      {
        await createNotification('error',data.message);
      }
    } else {
    
      // console.log("Form is invalid");
    }
  };


  render() {
    return (
      <>
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <Header name="Add Location Users" />

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-company">
              <p>
                <label>Select Location</label>
              </p>
              <Select
                options={this.state.options}
                onChange={this.selectChangeLocation}
              />


<p>
                <label>Select User</label>
              </p>
              <Select
                options={this.state.users}
                onChange={this.selectChangeUser}
              />

             
<button
                  className="w3-button w3-section w3-black w3-ripple"
                  disabled={!this.state.location_id || !this.state.user_id}
                  onClick={this.handleSubmit}
                >
                  Save 
                </button>
            </form>
          </div>
          <NotificationContainer />
        </div>
      </>
    );
  }
}
