import React from "react";
import "./view-reviewLanding.css";
import MaterialTable from "material-table";
import { GetReviewLandingPage } from "../homeService";
import { Header } from "../reusable/reusable";


export default class ViewReviewLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = { };
  }

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetReviewLandingPage();
    let companydata = data.data.data;
   // console.log(companydata)
    this.setState({ companydata });
  }

  handleFile = async (event) =>{

    let file = event.target.files[0];
    //console.log(file);
    this.setState({file:file})
}



  render() {
    return (
      <>
      <div
        className="w3-main"
        style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
      >
       <Header name="Review Landing Page Settings"/>

          <MaterialTable
            title=""
            columns={[
              { title: "ID", field: "id" },
              { title: "Image", field: "image", render: rowData => <img src={rowData.image} style={{width: 90, borderRadius: '50%'}}/>},
              { title: "Location Title", field: "location_title" },
              { title: "Message", field: "message" },
            ]}
            data={this.state.companydata}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </div>
      
      </>
    );
  }
}
