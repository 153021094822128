import React from "react";
import "./view-template.css";
import MaterialTable from "material-table";
import { GetTemplate,DeleteTemplate,Updatetemplate } from "../homeService";
import { Header,createNotification,ModalDisplay } from "../reusable/reusable";
import {NotificationContainer} from 'react-notifications';

export default class ViewTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editeddata: "", modalcompanyname: "",modalname:'',modalcontent:'',displayImage :''};
    // this.state = { company: [], editeddata: "", modalcompanyname: "",modalusername:'',modalfname:'',modallname:'',modalemail:'',modalpassword:'', modalId: "",selectedOption: null,startDate: '',file:null,url:'',location_name: "" ,displayImage :''};
    this.modalshowid = '04';
  }

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetTemplate();
    let companydata = data.data.data;
   // console.log(companydata)
    this.setState({ companydata });
  }

  handleFile = async (event) =>{

    let file = event.target.files[0];
    //console.log(file);
    this.setState({file:file})
}


handleClickDelete = async (event) => {
  // console.log(event.id);
   let deleteddata = await DeleteTemplate(event.id);
   let data = await GetTemplate();
   let companydata = data.data.data;
   //console.log(companydata)
   this.setState({ companydata });
   await createNotification("warning", deleteddata.message);
 };


 handleClickEdit = (event) => {
  // console.log(event);
  this.setState({ modalId: event.id, modalname: event.name,modalcontent: event.content,displayImage:event.image });
  document.getElementById(this.modalshowid).style.display = "block";
};


closemodal = () => {
  // debugger
  document.getElementById(this.modalshowid).style.display = "none";
  document.getElementById(`name ${this.state.modalId}`).value = "";
  document.getElementById(`content ${this.state.modalId}`).value = "";
  document.getElementById(`file ${this.state.modalId}`).value = "";

  // document.getElementById(this.state.displayImage).value = "";
};

handleEditForm = (event) => {
  console.log(event.target.name,event.target.value)
  this.setState({ [event.target.name]: event.target.value });
};




editdata = async () => {
  // console.log("called");
  //  console.log(this.state)

  const formdata = new FormData() 
  if(this.state.file !== null){
    formdata.append('image', this.state.file);
  }
 
  formdata.append('_method', 'PUT');
  formdata.append('name', this.state.modalname);
  formdata.append('content', this.state.modalcontent);
 
 

  let Updateddata = await Updatetemplate(
    this.state.modalId,
    formdata
  );
  let data = await GetTemplate();
  //console.log(data);
  let companydata = data.data.data;
  this.setState({ companydata });
  this.closemodal();
  await createNotification("success", Updateddata.message);
};




  render() {
    return (
      <>
      <div
        className="w3-main"
        style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
      >
       <Header name="Review Landing Page Settings"/>


       <ModalDisplay
            id= {this.modalshowid}
            passedFunction={this.closemodal}
            savedata={this.editdata}
            inputdatachange= {this.handleEditForm}
            filedatachange = {this.handleFile}
            dataid={this.state.modalId}



            name = {this.state.modalname}
            content = {this.state.modalcontent}
            file = {this.state.displayImage}
          
            modalheader="Update Template"
          />


          <MaterialTable
            title=""
            columns={[
              { title: "ID", field: "id" },
              { title: "Image", field: "image", render: rowData => <img src={rowData.image} style={{width: 90, borderRadius: '50%'}}/>},
              { title: "Location Title", field: "location_title" },
              { title: "Name", field: "name" },
              { title: "Content", field: "content" },
              { title: "Socile Account", field: "social_name" },
              { title: "Template Type", field: "template_link" },
            ]}
            data={this.state.companydata}
            actions={[
          
              {
                icon: "edit",
                tooltip: "Save User",
                onClick: (event, rowData) => this.handleClickEdit(rowData),
              },

              (rowData) => ({
                icon: "delete",
                tooltip: "Delete User",
                onClick: (event, rowData) => this.handleClickDelete(rowData),
                disabled: rowData.birthYear < 2000,
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
             <NotificationContainer/>
        </div>
      
      </>
      
    );
  }
}
