import React from "react";
import "./side-nav.css";
import { Sidemenu } from "../reusable/reusable";
import { Link, Redirect } from "react-router-dom";
import {logOut} from '../../../../actions/myaction';
import { connect } from "react-redux";

function w3_open() {
  document.getElementsByClassName("w3-sidenav")[0].style.display = "block";
  document.getElementsByClassName("w3-overlay")[0].style.display = "block";
}

function w3_close() {
  document.getElementsByClassName("w3-sidenav")[0].style.display = "none";
  document.getElementsByClassName("w3-overlay")[0].style.display = "none";
}


// Accordion

// Click on the "Jeans" link on page load to open the accordion for demo purposes
//   document.getElementById("myBtn").click();

 class SideNav extends React.Component {
  constructor(props) {
    super(props);
  }

  myAccFunc = (data) => {
    //debugger
    var x = document.getElementById(data);
    if (x.className.indexOf("w3-show") == -1) {
      x.className += " w3-show";
    } else {
      x.className = x.className.replace(" w3-show", "");
    }
  };

  render() {
    return (
      <div>
        <div
          className="w3-container w3-top w3-black w3-large w3-padding"
          style={{ zIndex: "105" }}
        >
          <button
            className="w3-btn w3-hide-large w3-padding-0 w3-hover-text-grey"
            onClick={w3_open}
          >
            <i className="fa fa-bars"></i>  Menu
          </button>
          {/* <span className="w3-right">Logo</span> */}
         
            
          <div className="w3-padding w3-left">
             BreezeReview BackEnd Setup
          </div>

          <div  onClick={this.handleLogout} className="w3-padding w3-right" style={{cursor: 'pointer'}}>
            <i className="fa fa-arrow-left"></i>  logout
          </div>
        </div>
        <nav
          className="w3-sidenav w3-collapse w3-white w3-animate-left"
          style={{ zIndex: "11", width: "300px" }}
        >
          <br />
          <div className="w3-container">
            {/* <h5 className="paddtop">Welcome</h5> */}
          </div>
          <a
            href="#"
            className="w3-padding-16 w3-hide-large w3-dark-grey w3-hover-black"
            onClick={w3_close}
            title="close menu"
          >
            <i className="fa fa-remove"></i>  Close Menu
          </a>
          <Link to="/dashboard" className="w3-padding">
            <i className="fa fa-bullseye"></i>  Dashboard
          </Link>
          <Sidemenu
            name="  Company "
            icon="fa fa-building"
            submenulink1="add_company"
            submenulink2="view_company"
            id="company"
          />
          <Sidemenu
            name="  Location "
            icon="fa fa-location-arrow"
            submenulink1="create_location"
            submenulink2="view_location"
            id="location"
          />

          <Sidemenu
            name="  Company Account "
            icon="fa fa-folder"
            submenulink1="add_company_account"
            submenulink2="view_company_account"
            id="CompanyAccount"
          />

          <Sidemenu
            name="  BackReview Header "
            icon="fa fa-commenting"
            submenulink1="add_backreview_header"
            submenulink2="view_backreview_header"
            id="BackReviewHeader"
          />

          <Sidemenu
            name="  BackCoupon Header "
            icon="fa fa-diamond"
            submenulink1="add_backcoupon_header"
            submenulink2="view_backcoupon_header"
            id="BackCouponHeader"
          />

          <Sidemenu
            name="  BackVip Header "
            icon="fa fa-file-pdf-o"
            submenulink1="add_backvip_header"
            submenulink2="view_backvip_header"
            id="BackVipHeader"
          />

          <Sidemenu
            name="  Users "
            icon="fa fa-users"
            submenulink1="add_users"
            submenulink2="view_users"
            id="user"
          />

          <Sidemenu
            name="  Location Users "
            icon="fa fa-user"
            submenulink1="add_location_users"
            submenulink2="view_location_users"
            id="LocationUser"
          />
          <Sidemenu
            name="  Twilio "
            icon="fa fa-phone"
            submenulink1="add_twilio"
            submenulink2="view_twilio"
            id="twilio"
          />

<Sidemenu
            name="  Social "
            icon="fa fa-bullhorn"
            submenulink1="add_social"
            submenulink2="view_social"
            id="Social"
          />

          <Sidemenu
            name="  GMB "
            icon="fa fa-google-plus-square"
            submenulink1="add_gmb"
            submenulink2="view_gmb"
            id="links"
          />

          <Sidemenu
            name="  GMB TinyUrl "
            icon="fa fa-google-plus"
            submenulink1="add_tinyurl"
            submenulink2="view_tinyurl"
            id="GMBtiny"
          />


<Sidemenu
            name="  FaceBook "
            icon="fa fa-facebook-square"
            submenulink1="add_facebook"
            submenulink2="view_facebook"
            id="facebooklinks"
          />

          <Sidemenu
            name="  FB TinyUrl "
            icon="fa fa-facebook"
            submenulink1="add_tinyfacebook"
            submenulink2="view_tinyfacebook"
            id="FBtiny"
          />


<Sidemenu
            name="  Yelp "
            icon="fa fa-yelp"
            submenulink1="add_yelp"
            submenulink2="view_yelp"
            id="yelp"
          />

          <Sidemenu
            name="  Yelp TinyUrl "
            icon="fa fa-info"
            submenulink1="add_tinyyelp"
            submenulink2="view_tinyyelp"
            id="yelptiny"
          />


<Sidemenu
            name="  Capterra "
            icon="fa fa-map-pin"
            submenulink1="add_capterra"
            submenulink2="view_capterra"
            id="capterra"
          />

          <Sidemenu
            name="  Capterra TinyUrl "
            icon="fa fa-thumb-tack"
            submenulink1="add_tinycapterra"
            submenulink2="view_tinycapterra"
            id="Capterratiny"
          />

<Sidemenu
            name="  BBB "
            icon="fa fa-external-link"
            submenulink1="add_bbb"
            submenulink2="view_bbb"
            id="BBB"
          />

          <Sidemenu
            name="  BBB TinyUrl "
            icon="fa fa-external-link-square"
            submenulink1="add_tinybbb"
            submenulink2="view_tinybbb"
            id="othertiny"
          />

          <Sidemenu
            name="  TrustPilot "
            icon="fa fa-star"
            submenulink1="add_trustpilot"
            submenulink2="view_trustpilot"
            id="trustpilot"
          />

          <Sidemenu
            name="  TrustPilot TinyUrl "
            icon="fa fa-star-o"
            submenulink1="add_tinytrustpilot"
            submenulink2="view_tinytrustpilot"
            id="trustpilot_tiny"
          />

          <Sidemenu
            name="  YellowPages "
            icon="fa fa-paper-plane"
            submenulink1="add_yellowpages"
            submenulink2="view_yellowpages"
            id="yellowpages"
          />

          <Sidemenu
            name="  YellowPages TinyUrl "
            icon="fa fa-paper-plane-o"
            submenulink1="add_tinyyellowpages"
            submenulink2="view_tinyyellowpages"
            id="yellowpages_tiny"
          />

          <Sidemenu
            name="  Custom URL "
            icon="fa fa-paperclip"
            submenulink1="add_custom"
            submenulink2="view_custom"
            id="customUrl"
          />

          <Sidemenu
            name="  Custom TinyUrl "
            icon="fa fa-link"
            submenulink1="add_customtiny"
            submenulink2="view_customtiny"
            id="custom_tiny"
          />


          <Sidemenu
            name="  Review LP Setting "
            icon="fa fa-files-o"
            submenulink1="add_reviewLanding"
            submenulink2="view_reviewLanding"
            id="review"
          />

          <Sidemenu
            name="  Alert LP Setting "
            icon="fa fa-exclamation"
            submenulink1="add_alertLanding"
            submenulink2="view_alertLanding"
            id="alert"
          />

          <Sidemenu
            name="  Back LP Setting "
            icon="fa fa-cogs"
            submenulink1="add_backLanding"
            submenulink2="view_backLanding"
            id="back"
          />

<Sidemenu
            name="  Template "
            icon="fa fa-file-text"
            submenulink1="add_template"
            submenulink2="view_template"
          
            id="template"
          />

{/* <Sidemenu
            name="  Location Social "
            icon="fa fa-link"
            submenulink1="add_template"
            submenulink2="view_template"
          
            id="templates"
          /> */}
          <div  onClick={this.handleLogout} className="w3-padding" style={{cursor: 'pointer'}}>
            <i className="fa fa-arrow-left"></i>  logout
          </div>
        </nav>
        <div
          className="w3-overlay w3-hide-large w3-animate-opacity"
          onClick={w3_close}
          style={{ cursor: "pointer" }}
          title="close side menu"
        ></div>
      </div>
    );
  }

   handleLogout = () => {
    localStorage.clear();
    this.props.changeName();
    
    //this.props.history.push("/login");
  };
  
}



const mapDispatchToProps = (dispatch)=>{
  return{
     changeName:() => {dispatch(logOut())}
  }
}

export default connect(null,mapDispatchToProps) (SideNav);