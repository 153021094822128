import API from "../../api";


  export function GetCompanyApi() {
    return API().get("company")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  export function CreateCompany(name) {
    return API().post("company", { name: name })
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  export function DeleteCompany(id) {
    return API().delete(`company/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  export function UpdateCompany(id, name) {
    return API().put(`company/${id}?name=${name}`)
      .then(function (response) {
        // console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  //  Location API() from here
  
  
  export function CreateLocation(formdata) {
  
    return API().post("location", formdata)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  export function GetLocationApi() {
    return API().get("location")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function UpdateLocation(id, data) {
    return API().post(`location/${id}`, data)
      .then(function (response) {
         //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function DeleteLocation(id) {
    return API().delete(`location/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  
  // Account Information
  
  
  export function GetAccountsApiList() {
    return API().get("companyAccount")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  
  export function GetAccountsApi(location_id) {
    return API().get(`companyAccountByLocation?location_id=${location_id}`)
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function CreateCompanyAccount(id,account_id) {
    return API().post("companyAccount", { location_id: id, account_id:account_id})
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  export function DeleteCompanyAccount(id) {
    return API().delete(`companyAccount/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  
  
  // this is the users API()
  
  export function GetUsersDataApi() {
    return API().get("user")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  export function CreateUser(formdata) {
  
    return API().post("user", formdata)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  export function DeleteUser(id) {
    return API().delete(`user/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function UpdateUser(id, data) {
    return API().post(`user/${id}`, data)
      .then(function (response) {
         //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  
  
  // Location Access User
  
  
  export function GetLocationUsers() {
    return API().get("locationAccessUser")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function DeleteLocationUsers(id) {
    return API().delete(`locationAccessUser/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function CreateLocationUsers(location_id,user_id) {
    return API().post("locationAccessUser", { location_id: location_id,user_id:user_id })
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  // twilio API here
  
  export function GetTwilioApi() {
    return API().get("twilio")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function CreateTwilio(lid,sid,token,number) {
  
    return API().post("twilio", {location_id:lid,sid:sid,token:token,number:number})
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  
  export function DeleteTwilio(id) {
    return API().delete(`twilio/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  
  // GMB API here
  
  
  export function CreateGMB(lid,placeid) {
  
    return API().post("gmb", {location_id:lid,place_id:placeid})
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  export function GetGmb() {
    return API().get("gmb")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function DeleteGmb(id) {
    return API().delete(`gmb/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  // Tiny Url API() here
  
  
  export function CreateTinyUrl(lid,url) {
  
    return API().post("tinyUrl", {location_id:lid,url:url})
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  export function GetTinyurl() {
    return API().get("tinyUrl")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  export function DeletetinyUrl(id) {
    return API().delete(`tinyUrl/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  // this is the ReviewLanding API()
  
  export function GetReviewLandingPage() {
    return API().get("reviewLPS")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  
  export function CreateReviewLandingPage(formdata) {
  
    return API().post("reviewLPS", formdata)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  // this is the Alert API()
  
  export function GetAlertLandingPage() {
    return API().get("alertLPS")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  
  
  export function CreateAlertLandingPage(formdata) {
  
    return API().post("alertLPS", formdata)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  // this is the BackLandingPage API()
  
  export function GetBackLandingPage() {
    return API().get("backLPS")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  export function CreateBackLandingPage(formdata) {
  
    return API().post("backLPS", formdata)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  // All the template code here
  
  export function CreateTemplate(formdata) {
  
    return API().post("templates", formdata)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  
  export function GetTemplate() {
    return API().get("templates")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  export function DeleteTemplate(id) {
    return API().delete(`templates/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  
  export function Updatetemplate(id, data) {
    return API().post(`templates/${id}`, data)
      .then(function (response) {
         //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  

// the facebook details are given here

export function GetFacebook() {
  return API().get("facebook")
    .then(function (response) {
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function DeleteFacebook(id) {
  return API().delete(`facebook/${id}`)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function CreateFacebook(lid,location_id) {
  
  return API().post("facebook", {location_id:lid,business_name:location_id})
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}


//tiny Url for facebook

export function GetTinyurlFacebook() {
  return API().get("facebookTinyurl")
    .then(function (response) {
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function DeletetinyUrlFacebook(id) {
  return API().delete(`facebookTinyurl/${id}`)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function CreateTinyUrlFacebook(lid,url) {
  
  return API().post("facebookTinyurl", {location_id:lid,url:url})
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}



// the social Location data API() here


export function GetSocial() {
  return API().get("locationSocials")
    .then(function (response) {
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function DeleteSocial(id) {
  return API().delete(`locationSocials/${id}`)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

  
export function CreateSocial(lid,social_id) {
  
  return API().post("locationSocials", {location_id:lid,social_id:social_id})
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}



// Yelp Api here

export function Createyelp(lid,url) {
  
  return API().post("yelp", {location_id:lid,business_id:url})
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}

export function Getyelp() {
  return API().get("yelp")
    .then(function (response) {
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function Deleteyelp(id) {
  return API().delete(`yelp/${id}`)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}


// Tiny URL of the Yelp

export function CreateTinyUrlYelp(lid,url) {
  
  return API().post("yelpTinyurl", {location_id:lid,url:url})
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}

export function GetTinyurlYelp() {
  return API().get("yelpTinyurl")
    .then(function (response) {
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function DeletetinyUrlYelp(id) {
  return API().delete(`yelpTinyurl/${id}`)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}



// capterra link here

export function CreateCapterra(lid,location_id) {
  
  return API().post("capterra", {location_id:lid,cid :location_id})
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}



export function GetCapterra() {
  return API().get("capterra")
    .then(function (response) {
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function DeleteCapterra(id) {
  return API().delete(`capterra/${id}`)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}


// Cpaterra tiny Url here


export function CreateTinyUrlCapterra(lid,url) {
  
  return API().post("capterraTinyurl", {location_id:lid,url:url})
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      //console.log(error);
    });
}

export function GetTinyurlCapterra() {
  return API().get("capterraTinyurl")
    .then(function (response) {
      // console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });
}


export function DeletetinyUrlCapterra(id) {
  return API().delete(`capterraTinyurl/${id}`)
    .then(function (response) {
      //console.log(response);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}


  // other API() here
  
  
  export function CreateOther(lid,placeid) {
  
    return API().post("bbb", {location_id:lid,business_link :placeid})
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  

  export function GetOther() {
    return API().get("bbb")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  

  export function DeleteOther(id) {
    return API().delete(`bbb/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  // create a other tiny URL

  export function CreateTinyUrlOther(lid,url) {
  
    return API().post("bbbTinyurl", {location_id:lid,url:url})
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
  
  export function GetTinyurlOther() {
    return API().get("bbbTinyurl")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  
  export function DeletetinyUrlOther(id) {
    return API().delete(`bbbTinyurl/${id}`)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // Trustpilot API()

  export function CreateTrustPilot(l_id,link) {
  
    return API().post("trustpilot", {location_id:l_id,business_link :link})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  export function GetTrustPilot() {
    return API().get("trustpilot")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  export function DeleteTrustPilot(id) {
    return API().delete(`trustpilot/${id}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

    // Tiny Trustpilot API()

    export function CreateTrustPilotTiny(l_id,link) {
  
      return API().post("trustpilotTinyurl", {location_id:l_id,url :link})
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    }
  
    export function GetTrustPilotTiny() {
      return API().get("trustpilotTinyurl")
        .then(function (response) {
          // console.log(response);
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  
    export function DeleteTrustPilotTiny(id) {
      return API().delete(`trustpilotTinyurl/${id}`)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }

// YellowPages API()

  export function CreateYellowPages(l_id,link) {
  
    return API().post("yellowpages", {location_id:l_id,business_link :link})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  export function GetYellowPages() {
    return API().get("yellowpages")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  export function DeleteYellowPages(id) {
    return API().delete(`yellowpages/${id}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // YellowPages TinyURL API()

  export function CreateYellowPagesTiny(l_id,link) {
  
    return API().post("yellowpagesTinyurl", {location_id:l_id,url :link})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  export function GetYellowPagesTiny() {
    return API().get("yellowpagesTinyurl")
      .then(function (response) {
        // console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  export function DeleteYellowPagesTiny(id) {
    return API().delete(`yellowpagesTinyurl/${id}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  // Custom URL API()
  export function CreateCustom(l_id,link) {
  
    return API().post("custom", {location_id:l_id,business_link: link})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  export function GetCustom() {
    return API().get("custom")
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  export function DeleteCustom(id) {
    return API().delete(`custom/${id}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

    // Custom TinyURL API()

    export function CreateCustomTiny(l_id,link) {
  
      return API().post("customTinyurl", {location_id:l_id,url :link})
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  
    export function GetCustomTiny() {
      return API().get("customTinyurl")
        .then(function (response) {
          // console.log(response);
          return response;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  
    export function DeleteCustomTiny(id) {
      return API().delete(`customTinyurl/${id}`)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  

  // Breezeback Header API()

  export function createBackHeader(account,l_id, header){
    return API().post(account, {location_id:l_id,header :header})
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  export function getBackHeader(account) {
    return API().get(account)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  export function updateBackHeader(account,id, header){
    return API().put(`${account}/${id}?header=${header}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  export function deleteBackHeader(account,id) {
    return API().delete(`${account}/${id}`)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  