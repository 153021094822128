import React from "react";
import "./view-company.css";
import MaterialTable from "material-table";
import { DeleteCompany, UpdateCompany, GetCompanyApi } from "../homeService";
import { ModalDisplay,Header,createNotification } from "../reusable/reusable";
import { NotificationContainer } from "react-notifications";

export default class ViewCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = { company: [], editeddata: "", modalname: "", modalId: "" };
    this.modalshowid = '01';
  }

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetCompanyApi();
    let companydata = data.data.data;
    this.setState({ companydata });
  }

  handleEditForm = (event) => {
    //console.log(event.target.id,event.target.value)
    this.setState({ modalname: event.target.value, modalId: event.target.id });
  };

  editdata = async () => {
    // console.log("called");
    let Updateddata = await UpdateCompany(
      this.state.modalId,
      this.state.modalname
    );
    let data = await GetCompanyApi();
    // console.log(data);
    let companydata = data.data.data;
    this.setState({ companydata });
    this.closemodal();
    await createNotification("success", Updateddata.message);
  };

  handleClickDelete = async (event) => {
   // console.log(event.id);
    let deleteddata = await DeleteCompany(event.id);
    let data = await GetCompanyApi();
    let companydata = data.data.data;
    this.setState({ companydata });
    await createNotification("warning", deleteddata.message);
  };

  handleClickEdit = (event) => {
   // console.log(event.id, event.name);
    this.setState({ modalId: event.id, modalname: event.name });
    // console.log(this.state);
    document.getElementById(this.modalshowid).style.display = "block";
  };

  closemodal = () => {
    // debugger
    document.getElementById(this.modalshowid).style.display = "none";
    document.getElementById(this.state.modalId).value = "";
  };

  render() {
    return (
      <>
       <NotificationContainer />
      <div
        className="w3-main"
        style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
      >
       <Header name="View Company"/>
          <ModalDisplay
            id= {this.modalshowid}
            passedFunction={this.closemodal}
            savedata={this.editdata}
            inputdatachange={this.handleEditForm}
            name={this.state.modalname}
            dataid={this.state.modalId}
            modalheader="Update Company Name"
          />

          <MaterialTable
            title=""
            columns={[
              { title: "ID", field: "id" },
              { title: "Name", field: "name" },
            ]}
            data={this.state.companydata}
            actions={[
              {
                icon: "edit",
                tooltip: "Save User",
                onClick: (event, rowData) => this.handleClickEdit(rowData),
              },
              (rowData) => ({
                icon: "delete",
                tooltip: "Delete User",
                onClick: (event, rowData) => this.handleClickDelete(rowData),
                disabled: rowData.birthYear < 2000,
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </div>
      
      </>
    );
  }
}
