import React from "react";
import "./view-users.css";
import MaterialTable from "material-table";
import { DeleteUser, UpdateUser, GetUsersDataApi } from "../homeService";
import { ModalDisplay,Header,createNotification } from "../reusable/reusable";
import { NotificationContainer } from "react-notifications";
import moment from 'moment'

export default class ViewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { company: [], editeddata: "", modalcompanyname: "",modalusername:'',modalfname:'',modallname:'',modalemail:'',modalpassword:'', modalId: "",selectedOption: null,startDate: '',file:null,url:'',location_name: "" ,displayImage :''};
    this.modalshowid = '03';
  }

  async componentDidMount() {
    // GetCompanyApi(res => this.setState({list: res}))
    let data = await GetUsersDataApi();
    let companydata = data.data.data;
   // console.log(companydata)
    this.setState({ companydata });
  }

  handleEditForm = (event) => {
    //console.log(event.target.name,event.target.value)
    this.setState({ [event.target.name]: event.target.value });
  };

  editdata = async () => {
    // console.log("called");
    //  console.log(this.state)

    const formdata = new FormData() 
    if(this.state.file !== null){
      formdata.append('image', this.state.file);
    }

    if(this.state.modalpassword !== ''){
      formdata.append('password', this.state.modalpassword);
    }
   
    formdata.append('username', this.state.modalusername);
    formdata.append('first_name', this.state.modalfname);
    formdata.append('last_name', this.state.modallname);
    formdata.append('email', this.state.modalemail);
   
   

    let Updateddata = await UpdateUser(
      this.state.modalId,
      formdata
    );
    let data = await GetUsersDataApi();
    //console.log(data);
    let companydata = data.data.data;
    this.setState({ companydata });
    this.closemodal();
    await createNotification("success", Updateddata.message);
  };

  handleClickDelete = async (event) => {
   // console.log(event.id);
    let deleteddata = await DeleteUser(event.id);
    let data = await GetUsersDataApi();
    let companydata = data.data.data;
    //console.log(companydata)
    this.setState({ companydata });
    await createNotification("warning", deleteddata.message);
  };

  handleClickEdit = (event) => {
    // console.log(event);
    this.setState({ modalId: event.id, modalcompanyname: event.company_name,modalusername:event.username,modalfname:event.first_name,modallname:event.last_name,modalemail:event.email,displayImage:event.image });
    document.getElementById(this.modalshowid).style.display = "block";
  };

 

  closemodal = () => {
    // debugger
    document.getElementById(this.modalshowid).style.display = "none";
     document.getElementById(`companyname ${this.state.modalId}`).value = "";
    document.getElementById(`file ${this.state.modalId}`).value = "";
    document.getElementById(`fname ${this.state.modalId}`).value = "";
    document.getElementById(`lname ${this.state.modalId}`).value = "";
    document.getElementById(`email ${this.state.modalId}`).value = "";
    document.getElementById(`password ${this.state.modalId}`).value = "";
    document.getElementById(`username ${this.state.modalId}`).value = "";
    // document.getElementById(this.state.displayImage).value = "";
  };

  handleFile = async (event) =>{

    let file = event.target.files[0];
    //console.log(file);
    this.setState({file:file})
  
    
}

handleDateChange = async (date) => {
  
  // var a =  date.dateFormat('m/d/Y');
   //console.log(date);
   var b = moment(date).format("M/D/Y");
  // console.log(b);
  this.setState({
    startDate: b
  });

};


  render() {
    return (
      <>
       <NotificationContainer />
      <div
        className="w3-main"
        style={{ marginLeft: "330px", marginTop: "43px", marginRight: "30px" }}
      >
       <Header name="View Users"/>
          <ModalDisplay
            id= {this.modalshowid}
            passedFunction={this.closemodal}
            savedata={this.editdata}
            inputdatachange= {this.handleEditForm}
            filedatachange = {this.handleFile}
            datehandle = {this.handleDateChange}
            dataid={this.state.modalId}
            companyname={this.state.modalcompanyname}
            username = {this.state.modalusername}
            fname= {this.state.modalfname}
            lname = {this.state.modallname}
            email = {this.state.modalemail}
            password = {this.state.modalpassword}
            file = {this.state.displayImage}
          
            modalheader="Update Users"
          />

          <MaterialTable
            title=""
            columns={[
              { title: "ID", field: "id" },
              { title: "Image", field: "image", render: rowData => <img src={rowData.image} style={{width: 30, borderRadius: '50%'}}/>},
              { title: "Email", field: "email" },
              { title: "Company Name", field: "company_name" },
          
              { title: "Name", field: "",render:rowData=>{
                return(
                `${rowData.first_name} ${rowData.last_name}`
                )
              },
             },
             { title: "Role", field: "role" },
            ]}
            data={this.state.companydata}
            actions={[
              {
                icon: "edit",
                tooltip: "Save User",
                onClick: (event, rowData) => this.handleClickEdit(rowData),
              },

              

              (rowData) => ({
                icon: "delete",
                tooltip: "Delete User",
                onClick: (event, rowData) => this.handleClickDelete(rowData),
                disabled: rowData.birthYear < 2000,
              }),
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
          />
        </div>
      
      </>
    );
  }
}
