import React  from "react";
import "./add-location.css";
import {CreateCompany} from '../homeService';
import {NotificationContainer} from 'react-notifications';
import { Header,createNotification } from "../reusable/reusable";
import { GetCompanyApi,CreateLocation } from "../homeService";

import Select from 'react-select'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment'

const timeZones = [
  { value: 'America/Edmonton', label: 'America/Edmonton' },
  { value: 'America/Halifax', label: 'America/Halifax' },
  { value: 'America/Moncton', label: 'America/Moncton' },
  { value: 'America/Montreal', label: 'America/Montreal' },
  { value: 'America/St_Johns', label: 'America/St_Johns' },
  { value: 'America/Toronto', label: 'America/Toronto' },
  { value: 'America/Regina', label: 'America/Regina' },
  { value: 'America/Thunder_Bay', label: 'America/Thunder_Bay' },
  { value: 'America/Vancouver', label: 'America/Vancouver' },
  { value: 'America/Winnipeg', label: 'America/Winnipeg' },
  { value: 'America/Whitehorse', label: 'America/Whitehorse' },
  { value: 'America/Yellowknife', label: 'America/Yellowknife' },
]

export default class AddLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { options: [],company_id:'',url:'',location_name: "",timeZone:'', formErrors: { location_name: "",url:"",file:"",selectedOption:"",startDate:"" },selectedOption: null,selectedTimeZone: null,startDate: '',file:null};
  }

  

  async componentDidMount() {
    let data = await GetCompanyApi();
    let companydata = await data.data.data;

    var output = await companydata.map(s => ({label:s.name,value:s.id}));
    this.setState({ options : output});
  }


  formValid = ({ formErrors }) => {
    // console.log(formErrors);
    let valid = true;

    Object.values(formErrors).forEach((val) => {
      // console.log(val)
      val.length > 0 && (valid = false);
    });
    return valid;
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      //console.log(this.state);
    });
    //console.log(this.state)
    let newform = this.state.formErrors;
    switch (name) {
      case "location_name":
        newform.location_name = value.length > 0 ? "" : "Location field cannot be empty";
        break;

        case "url":
        newform.url = value.length > 0 ? "" : "URL field cannot be empty";
        break;
        
        case "file":
          newform.file = value.length > 0 ? "" : "File not selected";
          break;

          case "startDate":
            newform.startDate = value.length > 0 ? "" : "Select Date";
            break;

      default:
        break;
    }
    this.setState({ newform, [name]: value });
  };



   handleSubmit = async (event) => {
    
    event.preventDefault();
   
    if (this.formValid(this.state) && this.state.company_name != "") {
      const formdata = new FormData() 
      formdata.append('logo', this.state.file);
      formdata.append('company_id', this.state.company_id);
      formdata.append('title', this.state.location_name);
      formdata.append('timezones',this.state.timeZone);
      formdata.append('url', this.state.url);
      formdata.append('start_date', this.state.startDate);
      let data = await CreateLocation(formdata)

      if(data.success == true){
        
      document.getElementById("add-Location").reset();
      this.setState({startDate:'',selectedOption:null,selectedTimeZone:null})
      await createNotification('success',data.message);
      }
      else
      {
        await createNotification('error',data.message);
      }
    } else {
    
      // console.log("Form is invalid");
    }
  };

  selectChange = selectedOption => {
    this.setState(
      { selectedOption },
      () => 
      this.setState({company_id:this.state.selectedOption.value})
    );
  };

  timeZoneHandler = selectedTimeZone => {
    this.setState(
      { selectedTimeZone },
      () => 
      this.setState({timeZone:this.state.selectedTimeZone.value})
    );
  };

  handleDateChange = async (date) => {
  
    // var a =  date.dateFormat('m/d/Y');
     //console.log(date);
     var b = moment(date).format("M/D/Y");
     //console.log(b);
    this.setState({
      startDate: b
    });
  
  };


  handleFile = async (event) =>{

      let file = event.target.files[0];
      // console.log(file);
      this.setState({file:file})
    
      
  }

  render() {
    const { selectedOption, selectedTimeZone } = this.state;
    return (
     
      <>
        <div
          className="w3-main"
          style={{ marginLeft: "300px", marginTop: "43px" }}
        >
          <Header name="Add New Location"/>

          <div className="w3-container  w3-margin-top">
            <form className="w3-container w3-card-4" id="add-Location">

            <p>
                <label>Select Company</label>
              </p>
              <Select options={this.state.options} onChange={this.selectChange} value={selectedOption}/>
            

              <p>
                <label>Location Name / title</label>
                <input
                  className="w3-input"
                  name="location_name"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.location_name.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.location_name}
                    <br />
                  </span>
                )}
              </p>

              <p>
                <label>Time Zone</label>
              </p>
              <Select options={timeZones} onChange={this.timeZoneHandler} value={selectedTimeZone} />


              <p>
                <label>URL Name</label>
                <input
                  className="w3-input"
                  name="url"
                  type="text"
                  onChange={this.handleChange}
                  noValidate
                />
                {this.state.formErrors.url.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.url}
                    <br />
                  </span>
                )}
              </p>

              <p>
                <label>File</label>
                <input
                  className="w3-input"
                  type="file"
                  name="file"
                  onChange={this.handleFile}
                  noValidate
                />
                {this.state.formErrors.file.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.file}
                    <br />
                  </span>
                )}
              </p>



             
                <label>Start Date</label><br/>
                <DatePicker className="w3-input" name="startDate" value={this.state.startDate}  onChange={this.handleDateChange}/>
                {this.state.formErrors.startDate.length > 0 && (
                  <span className="" style={{ color: "red" }}>
                    {this.state.formErrors.startDate}
                    <br />
                  </span>
                )}
              



              <p>
                <button
                  className="w3-button w3-section w3-black w3-ripple"
                  disabled={this.state.location_name === '' || this.state.url === '' || this.state.startDate === '' || this.state.file === null || this.state.company_id === ''}
                  onClick={this.handleSubmit}
                >
                  Add Company
                </button>
              </p>
            </form>
          </div>
          <NotificationContainer/>
        </div>
      
      </>
     
    );
  }
}
